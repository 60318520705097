import moment from "moment";
import { getOnlyDigits } from "../utils/utils";

const removeAscDesc = text =>
  text
    .replace("asc", "")
    .replace("desc", "")
    .trim();
const ascDescNumber = (text = "asc") => (text.includes("desc") ? -1 : 1);
export const paginate = {
  limit: value => ["$limit", value],
  skip: value => ["$skip", value],
  sort: value => [`$sort[${removeAscDesc(value)}]`, ascDescNumber(value)],
  order: value => [`$sort[${removeAscDesc(value)}]`, ascDescNumber(value)],
};

export const query = {
  liked: field => value => [`${field}[$iLike]`, `%${value}%`],
  like: field => value => [`${field}[$like]`, `%${value}%`],
  ilike: field => value => [`${field}[$iLike]`, `%${value.trim()}%`],
  onlyDigits: field => value => [`${field}`, getOnlyDigits(value)],
  startsWith: field => value => [`${field}[$iLike]`, `${value}%`],
  lte: field => value => [
    `[${field}][$lte]`,
    `${moment(value)
      .endOf("day")
      .local()
      .toISOString()}`,
  ],
  gte: field => value => [
    `[${field}][$gte]`,
    `${moment(value)
      .startOf("day")
      .local()
      .toISOString()}`,
  ],
  inDay: field => value => [
    [
      `${field}[$gte]`,
      moment(value)
        .startOf("day")
        .local()
        .toISOString(),
    ],
    [
      `${field}[$lte]`,
      moment(value)
        .endOf("day")
        .local()
        .toISOString(),
    ],
  ],
};

export const softDeleteStrategy = {
  remove: () => ({ deletedAt: new Date().toISOString() }),
  undoRemove: () => ({ deletedAt: null }),
  fetchList: () => ({}),
};
