import React from "react";
import {
  Select,
  List,
  Filter,
  useListRepository,
  useFilter,
  TextField,
} from "elentari";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import usersRepository from "./usersRepository";
import { useAbility } from ".";
import { getListActions } from "../../lib/crudAuthorization";
import { Email } from "../../Components";

export const ROLES = [
  { label: "Administrador", value: "ADMIN" },
  { label: "Usuário", value: "USER" },
];

const formatRole = value => {
  const role = ROLES.find(r => r.value === value);
  return role ? role.label : "";
};

const UserFilter = () => {
  const filter = useFilter({});
  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Field name="name" label="Nome" fullWidth component={TextField} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Email />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="role"
            label="Cargo"
            component={Select}
            options={ROLES}
            hideEmpty
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const UsersList = ({ ...props }) => {
  const abilities = useAbility();
  const listOptions = {
    defaultOrder: "name",
    fields: {
      name: {
        label: "Nome",
      },
      empresa: {
        label: "Empresa",
      },
      email: {
        label: "E-mail",
      },
      role: {
        label: "Cargo",
        format: role => formatRole(role),
      },
      recaptchaRequired: {
        label: "Recaptcha",
        format: recaptchaRequired => recaptchaRequired ? "Sim" : "Não",
      },
    },
  };

  const listHook = useListRepository({
    repository: usersRepository,
    defaultSort: "name",
    path: "users",
    forceRemove: true,
  });

  delete listHook.undoRemove;
  listHook.deleteFItem = listHook.removeItem;
  delete listHook.removeItem;

  const actions = getListActions(abilities, listHook, "users");
  return (
    <>
      <UserFilter />
      <List
        {...props}
        {...listHook}
        {...actions}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  );
};

export default UsersList;
