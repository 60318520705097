import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { Select, CrudForm, TextField, useEntityManager } from "elentari";
import usersRepository from "./usersRepository";
import { ROLES } from "./UsersList";
import { useAbility } from ".";
import { Email } from "../../Components";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";

const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Inválido")
    .required("Obrigatório"),
  name: yup.string().required("Obrigatório"),
  role: yup.string().required("Obrigatório"),
  password: yup
    .string()
    .notRequired()
    .when("isNew", (isNew, passwordSchema) =>
      isNew ? passwordSchema.required("Obrigatório") : passwordSchema
    ),
});

const validate = yupValidation(userSchema);

const UserForm = props => {
  const abilities = useAbility();

  const entityManager = useEntityManager({
    repository: usersRepository,
    handleError: data => data.message,
  });
  const cannotUpdate =
    abilities.cannot("update", "users") && !entityManager.isNew;
  const isNew = entityManager.isNew;

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        disableSubmit={cannotUpdate}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Field fullWidth name="name" label="Nome" component={TextField} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Email />
          </Grid>
          <Grid item sm={isNew ? 3 : 6} xs={12}>
            <Field
              fullWidth
              name="empresa"
              label="Empresa"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="role"
              label="Cargo"
              component={Select}
              options={ROLES}
              hideEmpty
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="recaptchaRequired"
              label="Obrigatório Recaptcha"
              component={Select}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              hideEmpty
            />
          </Grid>
          {isNew && (
            <Grid item sm={3} xs={12}>
              <Field
                fullWidth
                name="password"
                disabled={cannotUpdate}
                label="Senha"
                type="password"
                component={TextField}
              />
            </Grid>
          )}
        </Grid>
      </CrudForm>
    </>
  );
};

export default UserForm;
