import { AbilityBuilder } from "@casl/ability";

const subjectName = subject =>
  !subject || typeof subject === "string" ? subject : subject.__type;

export default user => {
  return AbilityBuilder.define({ subjectName }, (can, cannot) => {
    if (!user) return;

    if (user.role.includes("ADMIN")) {
      can("manage", "all");
    }
    if (user.role.includes("USER")) {
      cannot("manage", "all");
      can("read", "viagens");
    }
  });
};
