import React, { useContext, useEffect, useState } from "react";
import { MaskedField, useFilter, Snackbar, AuthContext } from "elentari";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form } from "react-final-form";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { cpfRegex } from "../../utils/regex";
import { formatDate } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  informacao: {
    padding: 32,
    height: "100%",
  },
  alerta: {
    padding: 32,
  },
  texto: {
    paddingTop: 32,
    paddingBottom: 32,
    height: "100%",
  },
  card: {
    height: "100%",
  },
}));

const UserFilter = ({ fetching }) => {
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const { initialValues, onSubmit } = useFilter({});
  const [token, setToken] = useState();
  const [showError, setShowError] = useState();
  const recaptchaRef = React.createRef();
  const siteKey = config.recaptchaSiteKey;

  const handleSubmit = data => {
    if (token || !user.recaptchaRequired) {
      onSubmit({ ...data, token });
      if (user.recaptchaRequired) {
        recaptchaRef.current.reset();
        setToken(null);
      }
    } else {
      setShowError(true);
    }
  };

  const onChange = token => {
    setToken(token);
    setShowError(false);
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Paper style={{ padding: 32 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item sm={4} xs={12}>
                <Field
                  name="cpf"
                  label="CPF"
                  fullWidth
                  mask={cpfRegex}
                  component={MaskedField}
                />
              </Grid>
              {user.recaptchaRequired && (<Grid item sm={2} xs={12}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={onChange}
                />
                {showError && (
                  <FormHelperText error>Obrigatório</FormHelperText>
                )}
              </Grid>)}
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      style={{ width: 120 }}
                      type="submit"
                      disabled={fetching}
                      color="primary"
                    >
                      {fetching && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </Form>
  );
};

const Viagens = () => {
  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState(false);

  const unmask = value => value && value.replace(/\D/g, "");

  useEffect(() => {
    const fetchData = async (cpf, token) => {
      if (cpf && cpf.length === 14) {
        setFetching(true);
        const response = await api.getViagens(unmask(cpf), token);
        if (response.ok) {
          setItem(response.data[0]);
        } else {
          setMessage(response.data.message);
        }
        setFetching(false);
      }
    };
    const params = new URLSearchParams(location.search);
    fetchData(params.get("cpf"), params.get("token"));
  }, [location.search]);

  const renderBloqueado = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Entre em contato com a Central de Cadastros!
        </Typography>
      </Grid>
    );
  };

  const renderEmptyViagens = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Motorista não possui histórico de viagens!
        </Typography>
      </Grid>
    );
  };

  const renderEmptyMotorista = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Motorista não possui cadastro!
        </Typography>
      </Grid>
    );
  };

  const renderMotorista = ({ nome, cpf = "" }) => {
    const cpfFormated = cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
    return (
      <Grid item xs={12}>
        <Typography variant="body1">Motorista: {nome}</Typography>
        <Typography variant="body1">CPF: {cpfFormated}</Typography>
      </Grid>
    );
  };

  const renderViagens = ({
    quantidade,
    destaques,
    quantidadeUltimas,
    viagens = [],
  }) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="body1">
            Total de Viagens: {quantidade}
          </Typography>
          <Typography variant="body1">
            Total de Viagens Mercadoria Específicas: {destaques}
          </Typography>
          <Typography variant="body1">
            Viagens nos últimos 90 dias: {quantidadeUltimas}
          </Typography>
          <Typography variant="body1">Detalhes Últimas:</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="Tabela de Viagens" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Origem</TableCell>
                  <TableCell>Destino</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Composição</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viagens.map((viagem, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(viagem.data)}</TableCell>
                    <TableCell>{viagem.origem}</TableCell>
                    <TableCell>{viagem.destino}</TableCell>
                    <TableCell>{viagem.produto}</TableCell>
                    <TableCell>{viagem.composicao}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  };

  const renderContent = info => {
    if (!info.success) {
      return renderBloqueado();
    }
    return (
      <Grid container spacing={2} justify="center" className={classes.card}>
        {info.motorista.nome ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Motorista</Typography>
            </Grid>
            {renderMotorista(info.motorista)}
            <Grid item xs={12}>
              <Typography variant="h6">Viagens</Typography>
            </Grid>
            {info.viagens.quantidade === 0
              ? renderEmptyViagens()
              : renderViagens(info.viagens)}
          </>
        ) : (
          renderEmptyMotorista()
        )}
      </Grid>
    );
  };

  const renderInfoG10 = infoG10 => {
    return (
      <Paper className={classes.informacao}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" align="center">
              <b>Viagens G10 - Transportes</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderContent(infoG10)}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderInfoTP = infoTP => {
    return (
      <Paper className={classes.informacao}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" align="center">
              <b>Viagens Transpanorama</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderContent(infoTP)}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <UserFilter fetching={fetching} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.alerta}>
          <Typography variant="caption" align="justify">
            A consulta prévia de referências comerciais e logísticas, e seus
            respectivos resultados são necessários para o cumprimento das
            obrigações legais, contratuais, securitárias e regulatórias dos
            contratados/subcontratados e contratantes/subcontratantes de
            serviços de transporte rodoviário e multimodal de cargas. Os
            resultados da consulta são confidenciais e não podem ser
            compartilhados ou utilizados para finalidades diversas dos previstas
            acima (referências comerciais e logísticas). O uso irregular ou mau
            uso, acarretará o bloqueio do acesso à ferramenta de consulta
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          className={classes.card}
        >
          <Grid item sm={6} xs={12} className={classes.card}>
            {item && renderInfoG10(item.g10)}
          </Grid>
          <Grid item sm={6} xs={12} className={classes.card}>
            {item && renderInfoTP(item.tp)}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar message={message} onClose={() => setMessage("")} />
    </Grid>
  );
};

export default Viagens;
