import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loginRoutes, PrivateRoute, Auth as AuthProvider } from "elentari";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
import {
  Login,
  ResetPassword,
  RecoverPassword,
  VerifyLogin,
} from "./modules/login";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import api from "./services/api";
import { Home } from "./HomeNavigation";
import usersRepository from "./modules/users/usersRepository";
import { AbilityContext, abilityFor } from "./modules/users";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  "@global": {
    "*::-webkit-scrollbar": {
      height: "0.6em",
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
});

const App = () => {
  const [ability, setAbility] = useState();

  const updateUser = async id => {
    const response = await usersRepository.getOne({ id });
    if (response.ok) {
      setAbility(abilityFor(response.data));
    }
  };

  const withUser = async user => {
    await api.setToken(user.token);
    updateUser(user.id);
  };

  return (
    <ThemeProvider theme={theme}>
      <AbilityContext.Provider value={ability}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthProvider
            withUser={withUser}
            onLogout={() => {
              setAbility(null);
            }}
          >
            <VerifyLogin />
            <Router>
              <Switch>
                <Route path={loginRoutes.login} component={Login} />
                <Route
                  path={loginRoutes.recoverPassword}
                  component={RecoverPassword}
                />
                <Route path="/init" component={ResetPassword} />
                <PrivateRoute path="/" render={() => <Home />} />
              </Switch>
            </Router>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </AbilityContext.Provider>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
