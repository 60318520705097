import formatString from "format-string-by-pattern";

export const formatDate = date => {
  return new Date(date).toLocaleString("pt-BR").split(" ")[0];
};

export const formatDateTime = date => {
  return new Date(date).toLocaleString("pt-BR");
};

export const getOnlyDigits = string => {
  return string ? string.replace(/[^\d]/g, "") : "";
};

export const formatCpf = string => {
  string = getOnlyDigits(string);
  return formatString("XXX.XXX.XXX-XX", string);
};
