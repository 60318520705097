import React from "react";
import { autocompleteHelpers } from "elentari";
import usersRepository from "./usersRepository";
import Autocomplete from "../../Components/Autocomplete";
const { toOption } = autocompleteHelpers;

export const loadUsers = async inputValue => {
  const response = await usersRepository.list({
    query: {
      name: inputValue,
      order: "name asc",
    },
    paginate: {
      limit: 10,
    },
  });
  if (response.ok) {
    return response.data.map(toOption("name"));
  }
  return [];
};

const UserAutocomplete = props => {
  return (
    <Autocomplete
      repository={usersRepository}
      labelOption="name"
      loadOptions={loadUsers}
      {...props}
    />
  );
};

export default UserAutocomplete;
