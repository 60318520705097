import React, { useState } from "react";
import {
  List,
  Filter,
  useListRepository,
  useFilter,
  Snackbar,
  MaskedField,
} from "elentari";
import UsersAutocomplete from "../users/UsersAutocomplete";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import logsRepository from "./logsRepository";
import { useAbility } from "../users";
import { getListActions } from "../../lib/crudAuthorization";
import { downloadCsv } from "../../utils/downloadCsv";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { formatDateTime, formatCpf } from "../../utils/utils";
import DatePicker from "../../Components/DatePicker";
import { cpfRegex } from "../../utils/regex";

const LogFilter = () => {
  const filter = useFilter({});
  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <Field
            name="dataInicio"
            label="Data Início"
            fullWidth
            component={DatePicker}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Field
            name="dataFim"
            label="Data Fim"
            fullWidth
            component={DatePicker}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Field
            name="cpf"
            label="CPF"
            fullWidth
            mask={cpfRegex}
            component={MaskedField}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Field
            name="userId"
            label="Usuario"
            fullWidth
            component={UsersAutocomplete}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const Logs = ({ ...props }) => {
  const [mensagem, setMensagem] = useState();
  const location = useLocation();
  const abilities = useAbility();
  const listOptions = {
    defaultOrder: "id desc",
    fields: {
      createdAt: {
        label: "Data",
        format: formatDateTime,
      },
      cpf: {
        label: "CPF",
        format: formatCpf,
      },
      user: {
        label: "Usuário",
        format: usuario => (usuario ? usuario.name : ""),
      },
    },
  };

  const onDownloadClick = async () => {
    setMensagem("Preparando download");
    const params = new URLSearchParams(location.search);
    const { sort = "id desc", ...allQuery } = R.fromPairs([
      ...params.entries(),
    ]);
    const limit = 50;
    let response = await logsRepository.list({
      query: {
        ...allQuery,
        ...(sort ? { sort } : {}),
        limit,
      },
    });
    if (response.ok) {
      if (response.count <= 2000) {
        let list = response.data;
        let total = response.count;
        let skip = 50;
        while (skip < total) {
          response = await logsRepository.list({
            query: {
              ...allQuery,
              ...(sort ? { sort } : {}),
              limit,
              skip,
            },
          });
          if (response.ok) {
            skip += limit;
            list = list.concat(response.data);
          } else {
            break;
          }
        }
        await downloadCsv(list, listOptions, true, true, "Logs");
        setMensagem("");
      } else {
        setMensagem(
          "O número de logs é muito alto, filtrar por períodos menores!"
        );
      }
    } else {
      setMensagem("Falha ao fazer download!");
    }
  };

  const listHook = useListRepository({
    repository: logsRepository,
    defaultSort: "id desc",
    path: "logs",
  });

  const actions = getListActions(abilities, listHook, "logs");
  return (
    <>
      <LogFilter />
      <List
        {...props}
        {...listHook}
        {...actions}
        onDownloadClick={onDownloadClick}
        onClickView={null}
        onClickEdit={null}
        removeItem={null}
        listOptions={listOptions}
      />
      <Snackbar message={mensagem} onClose={() => setMensagem("")} />
    </>
  );
};

export default Logs;
