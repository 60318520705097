import { restApi } from "../../services/api";
import { createBasicRepository as createRepository } from "elentari";
import { query, paginate } from "../../lib/feathers";

const logsRepository = createRepository({
  path: "logs",
  restApi,
  queryTransform: {
    cpf: query.onlyDigits("cpf"),
    dataInicio: query.gte("dataInicio"),
    dataFim: query.lte("dataFim"),
    ...paginate,
  },
});

export default logsRepository;
