import React, { useContext, useState } from "react";
import { AppWrap, RouteByMenu, AuthContext } from "elentari";
import { makeStyles } from "@material-ui/core/styles";
import AccountBox from "@material-ui/icons/AccountBox";
import MenuBook from "@material-ui/icons/MenuBook";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Switch, Redirect, Route } from "react-router-dom";
import * as R from "ramda";
import { useAbility } from "./modules/users";
import { UsersList, UserForm } from "./modules/users";
import { Viagens } from "./modules/viagens";
import logo from "./images/g10-logo-inverter.png";
import { Logs } from "./modules/logs";

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "100%",
  },
  logoOpen: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 128,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const allMenuItems = [
  {
    name: "users",
    label: "Usuários",
    icon: AccountBox,
    pathname: "/users",
    list: UsersList,
    form: UserForm,
  },
  {
    name: "viagens",
    label: "Viagens",
    icon: LocalShippingIcon,
    pathname: "/viagens",
  },
  {
    name: "logs",
    label: "Logs",
    icon: MenuBook,
    list: Logs,
    pathname: "/logs",
  },
];

export const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(localStorage.toggleDrawer === "1");
  const authContext = useContext(AuthContext);
  const user = authContext.user;

  const onToggleDrawer = status => {
    setOpen(status === "open");
  };

  let menuItems = [];
  const abilities = useAbility();
  if (abilities) {
    menuItems = allMenuItems.filter(({ name }) => {
      if (user.role !== "ADMIN" && name === "users") return false;
      return abilities.can("read", name);
    });
  }

  const defaultRedirect = R.pathOr("/", [0, "pathname"], menuItems);

  return (
    <AppWrap
      onToggleDrawer={onToggleDrawer}
      logo={
        <div className={open ? classes.logoOpen : classes.logoClose}>
          <img alt="logo G10" src={logo} className={classes.logo} />
        </div>
      }
      userAvatarProps={{
        action: "Sair",
        label: "Olá,",
      }}
      menuItems={menuItems}
    >
      <Switch>
        <Route exact path="/viagens" component={Viagens} />
        <Route exact path="/">
          <Redirect to="/viagens" />
        </Route>
        <RouteByMenu menuItems={menuItems} />
        <Redirect to={defaultRedirect} />
      </Switch>
    </AppWrap>
  );
};
